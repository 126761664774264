import React, { ButtonHTMLAttributes } from 'react';

import {
  HideableText,
  IconAndLabelContainer,
  StyledButton,
  StyledLink,
  StyledButtonProps,
} from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: 'button' | 'link';
  label: string;
  url?: string;
  variant?: StyledButtonProps['variant'];
  size?: StyledButtonProps['size'];
  fullWidth?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<ButtonProps> = React.memo(
  ({
    as = 'button',
    label,
    type = 'button',
    variant = 'primary',
    size = 'large',
    url,
    children,
    disabled,
    fullWidth,
    loading,
    onClick,
    ...props
  }) => {
    return (
      <React.Fragment>
        {as === 'link' ? (
          <StyledLink
            href={url}
            variant={variant}
            size={size}
            disabled={disabled}
            fullWidth={fullWidth}
          >
            <IconAndLabelContainer>
              <HideableText>{label || children}</HideableText>
            </IconAndLabelContainer>
          </StyledLink>
        ) : (
          <StyledButton
            type={type}
            variant={variant}
            label={label}
            size={size}
            disabled={disabled}
            fullWidth={fullWidth}
            onClick={onClick}
            {...props}
          >
            <IconAndLabelContainer>
              <HideableText>{label || children}</HideableText>
            </IconAndLabelContainer>
          </StyledButton>
        )}
      </React.Fragment>
    );
  }
);

export default Button;
