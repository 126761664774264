import React, { ButtonHTMLAttributes, LinkHTMLAttributes } from 'react';
import Styled from 'styled-components/macro';
import { lighten, darken } from 'polished';
import { Theme } from '../../theme';

export interface StyledButtonProps {
  label: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  variant: 'primary' | 'secondary' | 'accent' | 'gray';
  size: 'small' | 'large';
  children?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  theme: Theme;
}

export interface StyledLinkProps {
  variant: 'primary' | 'secondary' | 'accent' | 'gray';
  size: 'small' | 'large';
  href: LinkHTMLAttributes<HTMLLinkElement>['href'];
  children?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  theme: Theme;
}

interface HideableProps {
  hide?: boolean;
}

const getBackgroundColor = ({ theme, variant = 'primary' }: any) => {
  if (variant === 'primary') {
    return theme.colors.black;
  }

  if (variant === 'secondary') {
    return 'transparent';
  }

  if (variant === 'accent') {
    return theme.colors.blue;
  }

  if (variant === 'gray') {
    return theme.colors.lightGray;
  }

  return 'transparent';
};

const getBorder = ({ theme, variant = 'primary' }: any) => {
  if (variant === 'primary' || variant === 'secondary') {
    return `1px solid ${theme.colors.black}`;
  }

  if (variant === 'accent') {
    return `1px solid ${theme.colors.blue}`;
  }

  if (variant === 'gray') {
    return `1px solid ${theme.colors.lightGray}`;
  }

  return '0';
};

const getBackgroundColorActive = ({ theme, variant = 'primary' }: any) => {
  if (variant === 'primary') {
    return lighten(0.2, theme.colors.black);
  }

  if (variant === 'secondary') {
    return theme.colors.black;
  }

  if (variant === 'accent') {
    return darken(0.1, theme.colors.blue);
  }

  if (variant === 'gray') {
    return darken(0.1, theme.colors.lightGray);
  }

  return 'transparent';
};

const getBackgroundColorDisabled = ({ theme }: any) => {
  return theme.colors.disabledGray;
};

const getBorderColorDisabled = ({ theme }: any) => {
  return `1px solid ${theme.colors.disabledGray}`;
};

const getTextColor = ({ theme, variant = 'primary' }: any) => {
  if (['primary', 'accent',].includes(variant)) {
    return theme.colors.white;
  }

  if (['secondary', 'gray',].includes(variant)) {
    return theme.colors.black;
  }

  return theme.colors.black;
};

const getTextColorActive = ({ theme, variant = 'primary' }: any) => {
  if (['primary', 'accent', 'secondary'].includes(variant)) {
    return theme.colors.white;
  }
  
  return theme.colors.black;
};

export const HideableText = Styled.span<HideableProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  min-height: 3rem;
  line-height: 1;
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};
  opacity: ${props => (props.hide ? '0' : '1')};
  white-space: nowrap;
`;

export const IconAndLabelContainer = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledButton = Styled.button.attrs(({ disabled, type }) => ({
  type,
  disabled,
}))<StyledButtonProps>`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${props => props.size === 'small' ? '3.7rem' : '4.7rem'};
  min-height: ${props => props.size === 'small' ? '3.7rem' : '4.7rem'};
  line-height: 1;
  font-family: ${props => props.theme.fonts.body.family};
  font-weight: 500;
  font-size: ${props => props.size === 'small' ? '1.4rem' : '1.6rem'};
  vertical-align: middle;
  letter-spacing: -0.1px;
  background: ${getBackgroundColor};
  color: ${getTextColor};
  border: ${getBorder};
  padding: 0 ${props => props.size === 'small' ? '3rem' : '4rem'};;
  cursor: pointer;
  transition: 150ms;
  text-align: center;
  outline: none;
  border-radius: 10px;

  ${props => props.fullWidth && `
    display: block;
    width: 100%;
    left: 0;
    right: 0;
  `}

  &:hover, &:focus, &:active {
    color: ${getTextColorActive};
    background: ${getBackgroundColorActive};
    border: ${getBorder};
  }

  ${props => props.disabled && `
    cursor: not-allowed;
    color: ${props.theme.colors.white};
    background: ${getBackgroundColorDisabled(props)};
    border: ${getBorderColorDisabled(props)};
    
    &:hover, &:focus, &:active {
      color: ${props.theme.colors.white};
      background: ${getBackgroundColorDisabled(props)};
      border: ${getBorderColorDisabled(props)};
    }
  `}
`;

export const StyledLink = Styled.a.attrs(({ href }) => ({
  href
}))<StyledLinkProps>`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${props => props.size === 'small' ? '3.7rem' : '4.7rem'};
  min-height: ${props => props.size === 'small' ? '3.7rem' : '4.7rem'};
  line-height: 1;
  font-family: ${props => props.theme.fonts.body.family};
  font-weight: 500;
  font-size: ${props => props.size === 'small' ? '1.4rem' : '1.6rem'};
  vertical-align: middle;
  letter-spacing: -0.1px;
  background: ${getBackgroundColor};
  color: ${getTextColor};
  border: ${getBorder};
  padding: 0 ${props => props.size === 'small' ? '3rem' : '4rem'};;
  cursor: pointer;
  transition: 150ms;
  text-align: center;
  outline: none;
  border-radius: 10px;

  ${props => props.fullWidth && `
    display: block;
    width: 100%;
    left: 0;
    right: 0;
  `}

  &:link, &:visited {
    color: ${getTextColor};
  }

   &:hover, &:active {
    color: ${getTextColorActive};
    background: ${getBackgroundColorActive};
    border: ${getBorder};
  }

  ${props => props.disabled && `
    cursor: not-allowed;
    color: ${props.theme.colors.white};
    background: ${getBackgroundColorDisabled(props)};
    border: ${getBorderColorDisabled(props)};

    &:link, &:visited {
      color: ${getTextColor};
    }
    
    &:hover, &:active {
      color: ${props.theme.colors.white};
      background: ${getBackgroundColorDisabled(props)};
      border: ${getBorderColorDisabled(props)};
    }
  `}
`;
