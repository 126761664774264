import Styled from 'styled-components';
import Media from '@uizard-io/styled-media-query';

export interface WrapperProps {
  column?: boolean;
}

export const Wrapper = Styled.main<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin: 2rem;

  ${Media.greaterThan('medium')`
    flex-direction: row;

    ${(props: WrapperProps) => props.column && `
      flex-direction: column;
    `}
  `}

  ${Media.greaterThan('large')`
    flex-direction: row;
    margin: 2rem auto;
    max-width: 100rem;

    ${(props: WrapperProps) => props.column && `
      flex-direction: column;
    `}
  `}
`;